@import '../../../common/vars';

:global {
  html,
  body {
    overflow-x: hidden;
  }

  .layout {
    position: relative;
    transition: transform 0.7s;
    transition-timing-function: cubic-bezier(0.91, 0.01, 0.6, 0.99);
  }

  .store-menu-active {
    overflow: hidden;
  }

  .store-menu-active .layout {
    transform: translateX(100%);
  }

  .store-menu-active .light-talk-me {
    display: none;
  }

  @media (max-width: 940px), (hover: none) {
    .store-show-page {
      background: white;
    }
  }
}

.menu {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: white;
  transition: transform 0.5s;
  transition-timing-function: cubic-bezier(0.82, 0.01, 0.77, 0.78);
  transform: translateX(-50%);
  z-index: -1;

  @media (min-width: $md) {
    display: none;
  }
}

:global(.store-menu-active) .menu {
  transform: translateX(0);
  z-index: 0;
}

:global(.custom-store) .menu {
  transform: translateX(-100%);
  z-index: 1000;
}

:global(.store-menu-active) :global(.custom-store) .menu {
  transform: translateX(0);
  z-index: 1000;
}

button.closeButton {
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
}
